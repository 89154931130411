<template>
  <v-dialog v-model="visible"
    v-if="workOrder"
    persistent
    scrollable
    width="400"
    content-class="my-custom-dialog">
    <v-form ref="statusChange"
      @submit.prevent="submit">
      <v-card class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">Promijena statusa radnog naloga</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <label>
                Trenutni status: {{ showStatus(workOrder.current_status) }}
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select hide-details
                outlined
                label="Novi status"
                dense
                :items="statuses"
                v-model="selectedStatus"
                item-text="name"
                item-value="value"
                :rules="[rules.req]">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton"
            @click="close"> Odustani </v-btn>
          <v-btn id="success"
            class="btn successButton"
            type="submit">
            Završi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-overlay v-if="loading"
      absolute
      z-index="10">
      <v-progress-circular indeterminate
        size="64"
        color="primary"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import { functions } from '@/plugins/firebase'
import state from '@/state'
// import { v4 as uuidv4 } from 'uuid'
export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      visible: false,
      loading: false,
      workOrder: undefined,
      selectedStatus: undefined,
      statuses: [{
        name: 'Potvrđen', value: 'CONFIRMED'
      }
      ],
      rules: {
        req: rules.req()
      }
    }
  },
  computed: {},
  methods: {
    showStatus (status) {
      var text = ''
      if (status !== '') {
        switch (status) {
          case 'INITIAL':
            text = 'Najava'
            return text
          case 'CANCELED':
            text = 'Otkazan'
            return text
          case 'COMPLETED':
            text = 'Završen'
            return text
          case 'CONFIRMED':
            text = 'Potvrđen'
            return text
          case 'CREATED':
            text = 'Kreiran'
            return text
          case 'UPDATED':
            text = 'Izmjenjen'
            return text
        }
      }

      return text
    },
    open (workOrder) {
      if (!workOrder) return
      this.visible = true
      this.workOrder = clone(workOrder)

      if (this.workOrder.current_status && this.workOrder.current_status !== '') {
        switch (this.workOrder.current_status) {
          case 'INITIAL':
            this.statuses = [
              {
                name: 'Potvrđen', value: 'CONFIRMED'
              },
              {
                name: 'Otkazan', value: 'CANCELED'
              }
            ]
            break
          case 'CONFIRMED':
            this.statuses = [
              {
                name: 'Završen', value: 'COMPLETED'
              },
              {
                name: 'Otkazan', value: 'CANCELED'
              }
            ]
            break
          case 'COMPLETED':
            this.statuses = [

            ]
            break
          case 'UPDATED':
            this.statuses = [
              {
                name: 'Potvrđen', value: 'CONFIRMED'
              },
              {
                name: 'Otkazan', value: 'CANCELED'
              }
            ]
            break
        }
      }
    },
    close () {
      this.visible = false
    },
    submit () {
      if (!this.selectedStatus || this.selectedStatus === '') return
      var that = this
      this.loading = true
      try {
        var workorderApi = functions.httpsCallable('workorderapi')

        const params = {
          company_id: state.getCurrentCompany().id,
          workorder_id: this.workOrder.id,
          status: this.selectedStatus
        }

        workorderApi({
          action: {
            operation: 'update',
            entity: 'work_order_status',
            params: params
          }
        })
          .then(async (resp) => {
            that.close()
            that.$emit('refresh')
            that.loading = false
          }
          )
      } catch (err) {
        this.showMsgBox({
          text: err,
          actions: ['cancel'],
          cancelBtnText: 'OK'
        })
      }
    }
  }
}
</script>
