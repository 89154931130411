<template>
  <v-container fluid
    class="fill-height pa-0"
    ref="main">
    <v-row justify="center">
      <v-dialog v-model="dialog"
        v-if="dialog"
        content-class="my-custom-dialog"
        persistent
        max-width="60%"
        style="overflow-y: auto; border-radius: 12px !important"
        :scrollable="true">
        <v-overlay v-if="loading"
          z-index="1000"
          :absolute="absolute"
          :opacity="opacity"
          style="height: 100%; width: 100%; opacity: 1 !important">
          <v-progress-circular :size="100"
            :width="10"
            style="color: #04aa6d; z-index: 1000"
            indeterminate>Pričekajte</v-progress-circular>
        </v-overlay>

        <!-- <v-overlay v-if="loading" absolute>
          <v-progress-circular
            :size="90"
            :absolute="absolute"
            :opacity="opacity"
            :width="10"
            style="color: #04aa6d; z-index: 1000"
            indeterminate
            >Pričekajte</v-progress-circular
          >
        </v-overlay> -->
        <v-card>
          <v-card-title style="background-color: #1577da; font-weight: bold; color: white">
            <span class="text-h5"
              style="font-weight: bold">Račun {{ receiptNumber }}</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-container class="pb-0">
              <v-row dense>
                <v-col class="pb-4"
                  cols="3">
                  <v-text-field outlined
                    dense
                    v-model="receiptNumber"
                    label="Broj računa"
                    readonly
                    :class="this.location && this.cashRegister
                      ? 'normal'
                      : 'attention'
                      "
                    hide-details></v-text-field>
                </v-col>
                <v-col class="pb-4"
                  cols="3">
                  <v-select outlined
                    dense
                    :disabled="locationDisabled"
                    v-model="location"
                    :items="locations"
                    label="Prodajno mjesto"
                    item-text="name"
                    item-value="id"
                    return-object
                    @change="onSelectLocation"
                    hide-details></v-select>
                </v-col>
                <v-col class="pb-4"
                  cols="3">
                  <v-select outlined
                    dense
                    v-model="cashRegister"
                    :items="cashRegisters"
                    label="Blagajna"
                    item-text="text"
                    item-value="id"
                    return-object
                    hide-details
                    @change="onSelectRegister">
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-select outlined
                    dense
                    v-model="paymentType"
                    :items="paymentTypes"
                    label="Način plaćanja"
                    item-text="name"
                    item-value="value"
                    @change="onSelectPaymentType"
                    required
                    return-object
                    hide-details></v-select>
                </v-col>
              </v-row>

              <div v-for="(item, counter) in cart"
                class="pa-3"
                v-bind:key="counter"
                style="
                  background-color: aliceblue;
                  border-style: solid;
                  margin-bottom: 10px;
                ">
                <v-row>
                  <v-col cols="1"
                    class="pt-2 pl-6"><span style="
                        font-size: 20px !important;
                        font-weight: bold !important;
                      ">{{ counter + 1 }}.</span></v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1"
                    class="pr-4 pb-0 pt-0"
                    align="right">
                    <v-btn icon
                      @click="removeCartItem(counter)">
                      <v-icon color="red"
                        medium>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-combobox outlined
                      dense
                      v-model="selectedItems[counter]"
                      :items="items"
                      :loading="isLoading"
                      @input="selectItem(counter)"
                      hide-no-data
                      hide-selected
                      item-text="data.name"
                      item-value="data.id"
                      label="Artikl"
                      placeholder="Pocnite tipkati za pretragu"
                      :disabled="!cashRegister"
                      return-object
                      append-icon="">
                      <template v-slot:item="{ item }">
                        <template>
                          <v-list-item-avatar>
                            <img v-if="item.data.picture"
                              :src="item.data.picture" />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                item.data.name.toUpperCase()
                              }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              getCategory(item)
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ showItemPrice(item) }}
                          </v-list-item-action>
                        </template>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row dense
                  style="margin-top: -1rem">
                  <v-col cols="3">
                    <v-combobox dense
                      outlined
                      v-model="cart[counter].unit"
                      :items="cart[counter].units"
                      label="Jed. mjere"
                      item-text="storageUnit"
                      item-value="price[currency].price"
                      return-object
                      @change="updateCartItem(counter)"
                      :disabled="!cashRegister"
                      hide-details></v-combobox>
                  </v-col>
                  <v-col cols="3">
                    <v-select dense
                      outlined
                      v-model="cart[counter].tax"
                      :label="$t('$vuetify.taxName')"
                      :items="taxes"
                      item-text="name"
                      item-value="rate"
                      @change="updateCartItem(counter)"
                      :disabled="!cashRegister"
                      return-object
                      hide-details></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field outlined
                      dense
                      v-model="cart[counter].formattedQuantity"
                      hide-spin-buttons
                      type="number"
                      label="Količina"
                      suffix="x"
                      min="0"
                      @change="updateQuantity(counter)"
                      :disabled="!cashRegister"
                      hide-details>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field dense
                      outlined
                      type="number"
                      hide-spin-buttons
                      v-model="cart[counter].formattedPrice"
                      value="1"
                      label="Cijena"
                      min="0"
                      @change="updatePrice(counter)"
                      :disabled="!cashRegister"
                      :suffix="currencySymbol"
                      hide-details>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field dense
                      outlined
                      hide-spin-buttons
                      v-model="cart[counter].formattedDiscount"
                      type="number"
                      label="Popust"
                      suffix="%"
                      min="0"
                      @change="updateDiscount(counter)"
                      :disabled="!cashRegister"
                      hide-details>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="cart[counter].data.freeItem === true"
                  class="pt-0 mt-0">
                  <v-col cols="12"
                    class="d-flex pt-2 pb-2 mt-0 py-0 justify-end">
                    <v-radio-group class="pt-0 mt-0 small-radio"
                      row
                      v-model="cart[counter].data.type"
                      :rules="[(v) => !!v || 'Molimo odaberite']"
                      required
                      hide-details
                      dense>
                      <v-radio v-for="type in itemTypes"
                        :key="type.value"
                        :value="type.value"
                        :label="type.name"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
              <div class="d-flex mb-3 totals-row">
                <div>
                  <v-btn id="plusBtn"
                    :disabled="canAddNewItem() || !cashRegister"
                    color="warning"
                    style="font-size: 2rem"
                    @click="addEmptyCartItem()">
                    +
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div align="right">
                  <span ref="formatted_total"
                    class="total-price"
                    v-html="showTotal()"></span>
                </div>
                <div>
                  <v-select v-model="selectedCurrency"
                    class="curreny-selector"
                    :items="currencies"
                    outlined
                    dense
                    hide-details>
                  </v-select>
                </div>
              </div>
              <v-row dense>
                <v-col class="pb-3"
                  cols="3">
                  <label class="datepickerLabel">{{ invoiceDateLabel }}</label>
                  <date-picker :clearable="false"
                    prefix-class="xmx"
                    :append-to-body="false"
                    class="datetimepicker"
                    :disabled="datePickersDisabled"
                    v-model="invoiceDate"
                    type="datetime"
                    valueType="timestamp"
                    :placeholder="invoiceDateLabel"
                    format="DD.MM.YYYY HH:mm:ss"
                    :default-value="new Date()"
                    @change="changeValuedDt()"
                    :popup-style="{
                      top: '-274px',
                    }"></date-picker>
                </v-col>
                <v-col class="pb-3"
                  cols="3">
                  <label class="datepickerLabel">{{ valueDateLabel }}</label>
                  <date-picker :clearable="false"
                    prefix-class="xmx"
                    :append-to-body="false"
                    class="datetimepicker"
                    :disabled="datePickersDisabled"
                    v-model="valueDate"
                    type="datetime"
                    valueType="timestamp"
                    :placeholder="valueDateLabel"
                    format="DD.MM.YYYY HH:mm:ss"
                    :default-value="new Date()"
                    :popup-style="{
                      top: '-274px',
                    }"></date-picker> </v-col><v-col class="pb-3"
                  cols="3">
                  <label class="datepickerLabel">{{ deliveryDateLabel }}</label>
                  <date-picker :clearable="false"
                    prefix-class="xmx"
                    :append-to-body="false"
                    class="datetimepicker"
                    :disabled="datePickersDisabled"
                    v-model="deliveryDate"
                    type="datetime"
                    valueType="timestamp"
                    :placeholder="deliveryDateLabel"
                    format="DD.MM.YYYY HH:mm:ss"
                    :default-value="new Date()"
                    :popup-style="{
                      top: '-274px',
                    }"></date-picker>
                </v-col>
                <v-col class="pb-3"
                  cols="3">
                  <label class="datepickerLabel">Jezik računa</label>
                  <v-select style="max-height: 34px !important"
                    v-model="selectedLanguage"
                    :items="languages"
                    outlined
                    dense
                    item-text="name"
                    item-value="value"
                    required
                    return-object
                    hide-details></v-select>
                </v-col>
              </v-row>
              <v-expansion-panels style="margin-bottom: 10px"
                v-model="associatesPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Dodaj podatke o kupcu
                    <template v-slot:actions>
                      <v-icon> mdi-account-multiple-outline </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense
                      style="margin-top: -1rem">
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-combobox label="Kupac"
                          outlined
                          dense
                          :items="associates"
                          v-model="associateByName"
                          item-text="name"
                          item-value="text"
                          :disabled="!cashRegister"
                          @change="onSelectAssociate"></v-combobox>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-text-field v-model="associateAddress"
                          label="Adresa"
                          outlined
                          dense
                          persistent-hint
                          :disabled="!cashRegister"></v-text-field>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-combobox label="OIB"
                          outlined
                          dense
                          :items="associates"
                          v-model="associateByOib"
                          item-text="oibs[0]"
                          item-value="text"
                          :disabled="!cashRegister"
                          @change="onSelectAssociateOib"
                          @keyup.enter="onSelectAssociateOib"></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense
                      style="margin-top: -1.3rem">
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-text-field v-model="associateZip"
                          label="Poštanski broj"
                          outlined
                          dense
                          persistent-hint
                          :disabled="!cashRegister"></v-text-field>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-text-field v-model="associateCity"
                          label="Grad"
                          outlined
                          dense
                          persistent-hint
                          :disabled="!cashRegister"></v-text-field>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-select :items="countries"
                          :label="$t('$vuetify.formDetails.country')"
                          v-model="associateCountry"
                          outlined
                          dense
                          item-text="country"
                          item-value="country"
                          class="uppercased-input"
                          :disabled="!cashRegister"></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="
                      inEu &&
                      associateCountry !== 'Hrvatska' &&
                      associateCountry !== undefined &&
                      (this.paymentType.value === 'T' ||
                        this.paymentType.value === 'P')
                    "
                      style="margin-top: -3rem">
                      <v-spacer></v-spacer>
                      <v-col cols="4">
                        <v-radio-group v-model="selectedVies"
                          :rules="[(v) => !!v || 'Molimo odaberite']"
                          required>
                          <v-radio v-for="vies in vieses"
                            :key="vies.value"
                            :value="vies.value"
                            :label="vies.name"
                            :rules="[rules.req]"></v-radio>
                        </v-radio-group>
                        <v-btn href="https://ec.europa.eu/taxation_customs/vies/?locale=hr"
                          target="_blank">
                          Provjera VIES baze
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="
                      !inEu &&
                      associateCountry !== 'Hrvatska' &&
                      associateCountry !== undefined &&
                      (this.paymentType.value === 'T' ||
                        this.paymentType.value === 'P')
                    "
                      style="margin-top: -3rem">
                      <v-spacer></v-spacer>
                      <v-col cols="4">
                        <v-radio-group v-model="selectedCustomerType"
                          :rules="[(v) => !!v || 'Molimo odaberite']"
                          required
                          class="small-radio">
                          <v-radio v-for="type in customerTypes"
                            :key="type.value"
                            :value="type.value"
                            :label="type.name"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row v-if="showPnb()"
                justify="center"
                style="margin-bottom: -2rem">
                <v-col xl="2"
                  lg="2"
                  md="2"
                  sm="2">
                  <v-text-field v-model="pnbModel"
                    prefix="HR"
                    label="Model"
                    persistent-placeholder
                    outlined
                    dense></v-text-field>
                </v-col>
                <v-col xl="4"
                  lg="5"
                  md="6"
                  sm="6">
                  <v-text-field v-model="pnb"
                    label="Poziv na broj"
                    persistent-placeholder
                    outlined
                    dense></v-text-field>
                </v-col>
                <v-col xl="4"
                  lg="4"
                  md="4"
                  sm="4">
                  <v-select :items="pnbTypes"
                    v-model="pnbType"
                    item-text="text"
                    item-value="value"
                    persistent-placeholder
                    outlined
                    dense
                    @change="onPnbTypeChange">
                  </v-select>
                </v-col>
              </v-row>
              <v-expansion-panels style="margin-bottom: 10px"
                v-model="commentPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Dodaj napomenu
                    <template v-slot:actions>
                      <v-icon> mdi-receipt-text-outline </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense
                      style="margin-top: -1rem">
                      <v-col cols="12">
                        <v-textarea outlined
                          v-model="note"
                          :items="languages"
                          label="Napomena"
                          rows="3"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-card-text>
          <v-card-actions class="pr-10 pl-10">
            <v-row dense
              style="margin-top: 1rem; margin-bottom: 1rem">
              <v-spacer></v-spacer>
              <v-btn class="btn infoButton"
                style="margin-right: 1rem"
                @click="resetAll">
                Počisti
              </v-btn>
              <v-btn class="btn dangerButton"
                style="margin-right: 1rem"
                @click="close">
                Odustani
              </v-btn>
              <v-btn id="success"
                class="btn successButton"
                :disabled="!cashRegister"
                @click="validateForm"
                ref="footer">
                Završi
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <show-error ref="showError"></show-error>
    </v-row>
  </v-container>
</template>
<script>
import { auth, df, functions } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import { clone, DAY } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import countries from '@/mixins/countries'
import ShowError from '@/components/ShowError'
import rules from '@/plugins/rules'
import { remapItems } from '@/libs/receiptIssuing/items'
import { dateTimeToUnixTimestamp, getPosNote } from '@/libs/receiptIssuing/commons'
import { validateParamsForReceipt } from '@/libs/receiptIssuing/validation'
import { reprint } from '@/libs/bixolon/reprint'
import { print58 } from '@/libs/bixolon/print58'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hr.js'

export default {
  components: {
    ShowError,
    DatePicker
  },
  mixins: [countries],
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'openPdfPrint', 'showMsgBoxHtml'],
  props: {
    associates: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialog: false,
      isLoading: false,
      config: null,
      multiplier: null,
      selectedPrinter: null,
      selectedItems: [{ data: { name: '', id: '' } }],
      cartItem: { units: [] },
      currency: 'EUR',
      selectedCurrency: 'EUR', // samo za odabir simbola
      total: 0,
      fullTotal: 0,
      formatedTotal: '0,00',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // invoiceDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      invoiceDate: Date.now(),
      issueDateTime: (Math.floor(new Date().getTime() / 1000)),
      valueDate: this.getNextWorkingDay(Date.now()),
      deliveryDate: Date.now(),
      dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      locations: [],
      location: null,
      locationDisabled: false,
      cashRegisters: [],
      cashRegister: null,
      user: null,
      userIsSalesman: false,
      cashRegisterNumber: 0,
      cashRegisterType: '',
      listeners: [],
      items: [],
      cart: [],
      takenOibs: [],
      // associates: [],
      invoiceDateOpen: false,
      valueDateOpen: false,
      deliveryDateOpen: false,
      invoiceDateLabel: 'Datum računa',
      valueDateLabel: 'Datum valute',
      deliveryDateLabel: 'Datum isporuke',
      paymentType: {},
      paymentTypes: [],
      itemTypes: [
        { name: 'Usluga', value: 'SERVICE' },
        { name: 'Materijalno dobro', value: 'GOODS' }
      ],
      taxes: [
        { name: 'PDV 25%', value: 1.25, rate: 0.25 },
        { name: 'PDV 13%', value: 1.13, rate: 0.13 },
        { name: 'PDV 5%', value: 1.05, rate: 0.05 },
        { name: 'PDV 0%', value: 1, rate: 0 }
      ],
      languages: [
        { name: 'Hrvatski', value: 'hr' },
        { name: 'English', value: 'en' }
      ],
      currencies: ['EUR', 'USD', 'GBP'],
      euCountries: [],
      inEu: false,
      selectedVies: undefined,
      associatesPanel: [],
      associate: undefined,
      associateByName: undefined,
      associateByOib: undefined,
      associateAddress: undefined,
      associateZip: undefined,
      associateCity: undefined,
      associateCountry: undefined,
      pnb: undefined,
      pnbModel: undefined,
      pnbType: 0,
      pnbTypes: [{ text: 'Generiraj poziv po broju računa', value: 0, disabled: false }, { text: 'Generiraj poziv po OIB-u', value: 1, disabled: true }],
      selectedLanguage: { name: 'Hrvatski', value: 'hr' },
      loading: false,
      absolute: true,
      opacity: 1,
      commentPanel: [],
      note: '',
      selectedCustomerType: undefined,
      wholeSaleRcptType: '',
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      cartItems: [],
      formattedTotalMp: '0,00',
      formattedTotalVp: '0,00',
      registerListener: undefined,
      receipt: undefined,
      workorder: undefined
    }
  },

  computed: {
    receiptNumber () {
      if (!this.location) { return 'Broj računa' }
      if (!this.cashRegister) { return 'Broj računa' }
      return this.cashRegisterNumber +
        state.getCurrentCompany().receipt_numeration_separator +
        this.location.location_id +
        state.getCurrentCompany().receipt_numeration_separator +
        this.cashRegister.number
    },
    currencySymbol () {
      switch (this.selectedCurrency) {
        case 'USD':
          return '$'
        case 'GBP':
          return '£'
        case 'EUR':
        default: {
          return '€'
        }
      }
    },

    computedInvoiceDateFormatted () {
      return this.formatDate(this.invoiceDate)
    },

    computedValueDateFormatted () {
      return this.formatDate(this.valueDate)
    },

    computedDeliveryDateFormatted () {
      return this.formatDate(this.deliveryDate)
    },

    today () {
      const d = new Date()
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    },

    datePickersDisabled () {
      if (this.paymentType.value === 'G' || this.paymentType.value === 'K' || this.paymentType.value === 'O') {
        this.setDateToNow()
      }
      return this.paymentType.value === 'G' || this.paymentType.value === 'K' || this.paymentType.value === 'O'
      //  this.cashRegisterType === 'MP'
    },

    vieses () {
      return [
        { name: 'Kupac unutar VIES baze', value: 'inside' },
        { name: 'Kupac izvan VIES baze', value: 'outside' }
      ]
    },

    customerTypes () {
      return [
        { name: 'Kupac - pravna osoba', value: 'legal' },
        { name: 'Kupac - fizička osoba', value: 'person' }
      ]
    }
  },

  watch: {
    associateCountry (nv) {
      this.inEu = false
      this.euCountries.forEach(country => {
        if (country === nv) {
          this.inEu = true
        }
      })
    }
  },

  methods: {
    onSelectPaymentType () {
      if (this.paymentType.value === 'T') {
        this.cart.forEach((_, key) => {
          this.updateCartItem(key)
        })
      }

      this.calculateTotals()
    },
    getCashRegisterNumber (cashRegister, offer = false, virman = false) {
      this.cashRegisterType = cashRegister?.type

      if (!cashRegister) {
        return
      }

      if (offer) {
        return cashRegister.offer_sequence + 1
      } else if (virman) {
        return cashRegister.virman_sequence + 1
      } else {
        return cashRegister.bill_sequence + 1
      }
    },
    async onSelectRegister () {
      // Need to refresh register
      this.cashRegisterType = this.cashRegister.type
      this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister)

      await this.setPaymentTypes()

      if (this.cashRegister?.type === 'VP' || this.cashRegister?.type === 'ALL') {
        this.calculateReferenceNumber()
      }

      this.listeners.push(
        df
          .doc(`cash_registers/${this.cashRegister.id}`)
          .onSnapshot((doc) => {
            if (doc && doc.data()) {
              this.registerListener = doc.data()

              if (this.paymentType.value && this.paymentType.value === 'P') {
                this.cashRegisterNumber = this.registerListener.offer_sequence + 1
              } else if (this.paymentType.value && this.paymentType.value === 'V') {
                this.cashRegisterNumber = this.registerListener.virman_sequence + 1
              } else {
                this.cashRegisterNumber = this.registerListener.bill_sequence + 1
              }
              this.onSelectPaymentType()
              this.calculateReferenceNumber()
            }
          })
      )
    },

    async getCashRegisters () {
      let registers = []
      this.cashRegisters = []

      const res = await df.doc(`user_cash_registers/${auth.currentUser.uid}.${state.getCurrentCompany().id}.${this.location.id}`).get()

      if (res && res.data() && res.data().cash_registers && Object.keys(res.data().cash_registers).length > 0) {
        const data = res.data()
        if (data?.cash_registers) {
          registers = Object.keys(data.cash_registers)
            .map(key => {
              return data.cash_registers[key]
            })
            .filter(register => register?.resource_status === 'ACTIVE')
            .sort((reg1, reg2) => {
              if (reg1.id < reg2.id) return -1
              if (reg1.id > reg2.id) return 1
            })
        }

        registers.forEach(r => {
          this.cashRegisters.push({ ...r, text: r.name, value: r.id, disabled: false })
        })

        if (this.cashRegisters.length === 1) {
          this.cashRegister = this.cashRegisters[0]
          this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister)
          this.setPaymentTypes()
          this.getItems()
        }
      }

      // df.collection('cash_registers')
      //   .where('company_id', '==', state.getCurrentCompany().id)
      //   .where('location_id', '==', this.location.id)
      //   .get()
      //   .then((documentSnapshots) => {
      //     if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
      //       documentSnapshots.docs.forEach((location) => {
      //         const loc = location.data()
      //         const register = { ...loc, text: loc.name, value: loc.id, disabled: false }
      //         registers.push(register)
      //       })
      //     }
      //     this.cashRegisters = registers.sort((a, b) => a.text < b.text ? -1 : 1)
      //     if (this.cashRegisters.length === 1) {
      //       this.cashRegister = this.cashRegisters[0]
      //       this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister)
      //       this.setPaymentTypes()
      //       this.getItems()
      //     }
      //   })

      this.calculateReferenceNumber()
    },
    async onSelectLocation () {
      await this.getItems()
      this.setReceiptData()

      await this.getCashRegisters()
      // state.setPointOfSale(this.location)
    },
    changeValuedDt () {
      if (this.paymentType.value === 'P' || this.paymentType.value === 'T') {
        this.valueDate = this.getNextWorkingDay(this.invoiceDate)
      }
    },
    setDateDefault () {
      this.invoiceDate = Date.now()
      this.valueDate = this.getNextWorkingDay(Date.now())
      this.deliveryDate = Date.now()
    },
    setDateToNow () {
      this.invoiceDate = Date.now()
      this.valueDate = Date.now()
      this.deliveryDate = Date.now()
    },
    showTotal () {
      if (this.paymentType.value !== 'T' && this.paymentType.value !== 'P') {
        return this.formatedTotal
      } else {
        return this.formattedTotalVp + ' <span style="font-size: 16px">(' + this.formattedTotalMp + ')</span>'
      }
    },
    showItemPrice (item) {
      if (this.paymentType.value !== 'T' && this.paymentType.value !== 'P') {
        return this.formatNumber(
          item,
          item.data.prices[this.currency].price / 100)
      } else {
        return this.formatNumber(
          item,
          item.data.prices[this.currency].base / 100
        ) + ' (' + this.formatNumber(
          item,
          item.data.prices[this.currency].price / 100
        ) + ')'
      }
    },
    validate (item) {
      if (!item.unit || !item.tax || !item.price) {
        return false
      }
      return true
    },
    getPayload (args, items) {
      return {
        action: {
          operation: 'set',
          entity: 'receipt',
          params: {
            company_id: state.getCurrentCompany().id,
            location_id: this.location.id,
            register_id: this.cashRegister.id,
            issue_date_time: dateTimeToUnixTimestamp(new Date(this.invoiceDate)),
            payment_method: args.paymentMethod,
            seller_oib: '',
            currency: args.currency,
            language: args.language.value,
            type: args.type,
            items: items,
            note: args.posNote,
            fiscalize: args.fiscalize
          }
        }
      }
    },
    getReferenceNumber () {
      if (this.pnbModel && this.pnb) {
        return `HR${this.pnbModel} ${this.pnb}`
      }
      return ''
    },
    async createReceipt () {
      this.loading = true
      let validated = true
      this.cart.forEach(it => {
        if (!this.validate(it)) {
          var msg = this.getValidationMessage(it)
          this.loading = false
          validated = false
          this.showMsgBoxHtml({ text: msg, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        }
      })
      if (!validated) return

      const method = this.paymentType.value
      let type = this.paymentType.type

      if (this.receipt.type.flag === 'P') {
        type = this.receipt.type.flag
      }

      let entity = ''

      this.cart.forEach(it => {
        it.data.quantity = it.quantity
        if (it.data.discount) {
          it.data.discounted_price *= 100
        }
        if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
          if (!it.manualPrice) {
            it.data.prices[this.currency].price = it.price * 100
            it.manual_price_input = false
          } else {
            it.manual_price_input = true
            it.data.prices[this.currency].price = Number((it.manualPrice * 100).toFixed(2))
          }
        }

        if (!it.manualPrice) {
          it.data.prices[this.currency].price = Number(it.data.prices[this.currency].price.toFixed(2))
        } else {
          it.manual_price_input = true
          it.data.prices[this.currency].price = Number((it.manualPrice * 100).toFixed(2))
        }

        if (!it.data.prices[this.currency].taxes || it.data.prices[this.currency].taxes.length === 0) {
          const tax = { ...it.tax }
          tax.rate *= 10000
          it.data.prices[this.currency].taxes.push(tax)
        }

        if (it.tax) {
          const tax = { ...it.tax }
          tax.rate *= 10000

          it.data.prices[this.currency].taxes = [tax]
        }

        it.data.selling_unit = it.unit.storageUnit ?? it.unit
      })

      const formattedItems = this.cart.map(i => {
        return i.data
      })

      const data = {
        currency: this.currency,
        fullTotal: this.fullTotal,
        items: formattedItems,
        paymentConfig: {},
        registerType: this.cashRegisterType,
        title: '',
        total: this.total,
        totalShoppingCart: 0,
        totalWithDiscount: 0,
        type: method
      }

      // if (this.paymentType.value === 'T') {
      //   formattedItems.forEach((_, key) => {
      //     formattedItems[key].prices[this.currency].price = formattedItems[key].prices[this.currency].base
      //   })
      // }

      const remappedItems = await remapItems(data, method, this.cashRegister)

      let selectPaymentMethod

      let pt = this.paymentType.value
      if (!pt) { pt = this.paymentType }

      switch (pt) {
        case 'G':
          selectPaymentMethod = 'NOVČANICE'
          entity = 'receipt'
          break
        case 'K':
          selectPaymentMethod = 'KARTICA'
          entity = 'receipt'
          break
        case 'O':
          selectPaymentMethod = 'OSTALO'
          entity = 'receipt'
          break
        case 'T':
          selectPaymentMethod = 'TRANSAKCIJSKI RAČUN'
          entity = 'receipt'
          break
      }

      var userID = auth.currentUser.uid

      const args = {
        entity: entity,
        reqId: uuidv4(),
        seller_id: userID,
        currency: this.currency,
        paymentMethod: selectPaymentMethod,
        language: this.selectedLanguage,
        type: {
          flag: type
        },
        posNote: await getPosNote(state),
        fiscalize: false
      }

      let associate

      if (this.associateByName) {
        associate = this.associateByName
      }
      if (this.associateByOib && typeof (this.associateByOib) !== 'string') {
        associate = this.associateByOib
      }

      if (typeof (associate) === 'string') {
        // slobodan unos
        args.type.payer_name = associate
        args.type.payer_oib = this.associateByOib
        args.type.payer_address = this.associateAddress
        args.type.payer_zip_code = this.associateZip
        args.type.payer_city = this.associateCity
        args.type.payer_country = this.associateCountry
      } else {
        // Odabir
        if (associate) {
          args.type.payer_address = this.associateAddress
          args.type.payer_city = this.associateCity
          args.type.payer_country = this.associateCountry
          args.type.payer_name = associate.name
          args.type.payer_oib = associate.oibs && associate.oibs[0] ? associate.oibs[0] : ''
          args.type.payer_zip_code = this.associateZip
        }
      }

      // provjeri da li je datum izdavanja racuna promijenjen
      const issueDateToUse = dateTimeToUnixTimestamp(new Date(this.invoiceDate))

      const httpArgs = {
        issueDateTime: issueDateToUse,
        paymentMeth: args.paymentMethod,
        fiscalize: false,
        language: args.language,
        type: args.type,
        openPdfPrint: this.openPdfPrint,
        receiptNumber: this.cashRegisterNumber
      }
      this.cashRegisterNumber = httpArgs.receiptNumber
      this.calculateReferenceNumber()

      const items = remappedItems

      items.forEach(i => {
        i.price = Number(i.price.toFixed(2))

        if (i.discounted_price) {
          i.discounted_price = Number(i.discounted_price.toFixed(2))
        }
      })

      const payload = this.getPayload(args, items)

      httpArgs.msgBox = this.showMsgBox
      httpArgs.confirm = this.confirm
      httpArgs.confirmClose = this.confirmClose
      httpArgs.hideLoader = this.hideLoader

      if (this.cashRegisterType !== 'MP') { httpArgs.overrideValueDateTime = dateTimeToUnixTimestamp(new Date(this.valueDate)) }
      if (this.deliveryDate && entity === 'vp_receipt' && this.cashRegisterType !== 'MP') {
        httpArgs.delivery_date = dateTimeToUnixTimestamp(new Date(this.deliveryDate))
      }

      data.fullTotal = Math.round(data.fullTotal)
      data.total = Math.round(data.total)

      if (data.fullTotal !== undefined && data.fullTotal > 0) {
        payload.action.params.full_total = data.fullTotal
      } else {
        payload.action.params.full_total = data.total
      }

      payload.action.params.number = this.cashRegisterNumber
      payload.action.params.full_total = this.fullTotal * 100
      payload.action.params.total = this.total * 100

      // Poziv na broj
      payload.action.params.payment_reference = this.getReferenceNumber()

      if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
        payload.action.params.print_size = state.getPrinter().value
      } else {
        payload.action.params.print_size = 'BIXOLON80'
      }

      payload.action.params.note = this.note

      let wholeSaleRcptType = ''

      // POTREBNO ZBOG ISPISA ČLANKA NA RAČUNU
      if ((pt === 'T' || pt === 'P') && this.associateCountry !== undefined && this.associateCountry !== '' && this.associateCountry !== 'Hrvatska') {
        if (state.getCurrentCompany().is_taxed === true) {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'TAX_EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'TAX_EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'TAX_OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'TAX_OTHER_LEGAL_ENTITY'
            }
          }
        } else {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'OTHER_LEGAL_ENTITY'
            }
          }
        }
      }

      if (wholeSaleRcptType !== '') {
        payload.action.params.wholesale_receipt_type = wholeSaleRcptType
      }

      // if currency is overriden
      if (this.selectedCurrency !== data.currency) {
        data.currency = this.selectedCurrency
        payload.action.params.currency = this.selectedCurrency
      }

      const additionalData = {
        locationId: this.location.id,
        registerId: this.cashRegister.id,
        callNewPrint: true,
        cashRegister: this.cashRegister,
        receiptId: this.receipt.id
      }

      await this.sendHttp(data, items, payload, httpArgs, this.selectedPrinter, additionalData)
    },

    async sendHttp (data, items, payload, args, printer, additionalData = null) {
      var receiptApi = functions.httpsCallable('receiptapi')

      const companyId = additionalData?.companyId ?? state.getCurrentCompany().id
      const locationId = additionalData?.locationId ?? state.getPointOfSale().id
      const registerId = additionalData?.registerId ?? state.getCashRegister().id

      const params = {
        company_id: companyId,
        location_id: locationId,
        register_id: registerId,
        issue_date_time: args.issueDateTime,
        payment_method: args.paymentMeth,
        seller_oib: '',
        currency: data.currency,
        payment_reference: payload.action.params.payment_reference ?? '',
        language: args.language.value,
        type: payload.action.params.type,
        items: items,
        note: payload.action.params.note,
        number: parseInt(args.receiptNumber),
        fiscalize: args.fiscalize,
        reqid: additionalData.receiptId
      }

      if (payload.action?.params?.wholesale_receipt_type) {
        params.wholesale_receipt_type = payload.action.params.wholesale_receipt_type
      }

      if (args.delivery_date) { params.delivery_date = args.delivery_date }

      if (['T58', 'T80', 'A4'].includes(printer.width)) {
        params.print_size = printer.width
        params.generate_pdf = true
      } else {
        params.generate_pdf = false
        params.print_size = 'BIXOLON80'
      }

      if (data.discount !== undefined) {
        params.discount = data.discount
      }

      if (data.discount) {
        params.discount = data.discount
      }

      if (data.fullTotal && data.fullTotal > 0) {
        params.full_total = Math.round(data.fullTotal)
      } else {
        params.full_total = Math.round(data.total)
      }

      if (data.total && data.total > 0) {
        params.total = Math.round(data.total)
      }

      if (data.totalWithDiscount && data.totalWithDiscount > 0) {
        params.total = Math.round(data.totalWithDiscount)
      }

      if (args.paymentMeth === 'OSTALO' && args.reprezentacija && args.reprezentacija === true) {
        if (!params.tags) {
          params.tags = []
        }
        params.tags.push({ receipt_type: 'REPREZENTACIJA' })

        var reprezentacijaAmount = 200
        if (Date.now() / 1000 > 1672527599) {
          reprezentacijaAmount = 50
        }

        const tempTotal = params.total - reprezentacijaAmount
        params.total = reprezentacijaAmount

        const discount = {
          active: true,
          amount: tempTotal,
          basketDiscount: true,
          company_id: state.getCurrentCompany().id,
          id: uuidv4(),
          name: 'Reprezentacija',
          type: 'AMOUNT'
        }
        params.discount = discount
        data.discount = discount
        data.total = reprezentacijaAmount / 100
      }

      if (args.delivery && args.delivery === true) {
        if (!params.tags) {
          params.tags = []
        }
        params.tags.push({ receipt_type: 'DELIVERY' })
        params.tags.push({ delivery_brand: args.deliveryName })
      }

      if (this.workorder) {
        if (!params.tags) {
          params.tags = []
        }
        params.tags.push({ work_order_id: this.workorder.id })
      }

      if (args.paymentCard && args.paymentCard !== '') {
        if (!params.tags) {
          params.tags = []
        }
        params.tags.push({ payment_card: args.paymentCard })
      }

      var userID = auth.currentUser.uid
      if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
        userID = state.getCashRegisterUser().id
      }

      params.seller_id = userID

      var cashRegisterType = additionalData?.cashRegister?.type ?? state.getCashRegister().type

      var validate = true
      if (cashRegisterType === 'ALL' && params.payment_method === 'TRANSAKCIJSKI RAČUN') {
        validate = false
      }

      if (validate) {
        var validationData = validateParamsForReceipt(params)

        if (validationData.valid === false) {
          args.hideLoader()
          EventBus.$emit('error-return')
          EventBus.$emit('showError', validationData)
          return
        }
      }

      if (params.type.flag === 'R') {
        if (args.overrideValueDateTime) {
          params.payment_due = args.overrideValueDateTime
        } else {
          params.payment_due = parseInt(dateTimeToUnixTimestamp(new Date(new Date().getTime() + 8 * DAY)))
        }
      }

      if (payload.action.entity === 'offer') {
        params.generate_pdf = false
        delete params.fiscalize
        delete params.print_size
        delete params.seller_id
        delete params.seller_oib
        params.type.flag = 'P'
        // params.offer_due = parseInt(dateTimeToUnixTimestamp(new Date(new Date().getTime() + 8 * DAY)))
        params.items = payload.action.params.items

        if (args.overrideValueDateTime) {
          params.offer_due = args.overrideValueDateTime
        } else {
          params.offer_due = parseInt(dateTimeToUnixTimestamp(new Date(new Date().getTime() + 8 * DAY)))
        }
      }

      receiptApi({
        action: {
          operation: 'set',
          entity: payload.action.entity,
          params: params
        }
      })
        .then((result) => {
          var receipttemplate = functions.httpsCallable('receiptTemplate')

          let printTemplate = ''
          if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
            printTemplate = state.getCurrentCompany().print_template
          }

          var errorReceiptMsg = 'računa'
          if (payload.action.entity === 'offer') {
            errorReceiptMsg = 'ponude'
          }

          if (result && result.data && result.data && result.data.code !== 200) {
            args.hideLoader()
            EventBus.$emit('error-return')

            var confirm = args.confirm
            var confirmClose = args.confirmClose
            confirm({
              title: 'Pogreška prilikom izdavanja ' + errorReceiptMsg,
              message: 'Molimo provjerite da li se račun kojeg ste upravo izdali nalazi na listi računa.',
              options: {
                toolbar: true,
                width: 410,
                confirmText: 'OK',
                hideCancel: true
              }
            }).then(_ => {
              confirmClose()
              EventBus.$emit('open_receipts')
            })
            return
          }

          if (result && result.data && result.data.zki !== '') {
            data.removeOrderFromTable = args.removeOrderFromTable
            if ((!printer.width || printer.width === '80') && result.data.full_receipt.payment_method !== 'TRANSAKCIJSKI RAČUN') {
              // Printer stare konfiguracije nema definiranu širinu
              reprint(printer, result.data.full_receipt, 2)
              args.hideLoader()
              this.stopLoader()
            } else if (printer.width && printer.width === '58' && result.data.full_receipt.payment_method !== 'TRANSAKCIJSKI RAČUN') {
              print58(printer, result.data.full_receipt, 2)
              args.hideLoader()
              this.stopLoader()
            } else if ((printer.width && ['T58', 'T80', 'A4'].includes(printer.width)) || result.data.full_receipt.payment_method === 'TRANSAKCIJSKI RAČUN') {
              var documentType = 'racun'
              if (payload.action.entity === 'offer') {
                documentType = 'ponuda'
              }

              receipttemplate({
                template: printTemplate,
                companyId: result.data.full_receipt.company_details.id,
                documentId: result.data.full_receipt.id,
                documentType: documentType,
                rawJson: ''
              })
                .then((result) => {
                  if (result.data?.document !== '') {
                    args.openPdfPrint(result.data?.document)
                    args.hideLoader()
                    this.stopLoader()
                  }
                })
            }
          } else if (payload.action.entity === 'offer') {
            receipttemplate({
              template: printTemplate,
              companyId: companyId,
              documentId: params.reqid,
              documentType: 'ponuda',
              rawJson: ''
            })
              .then((result) => {
                if (result.data?.document !== '') {
                  args.openPdfPrint(result.data?.document)
                  args.hideLoader()
                  this.stopLoader()
                }
              })
          } else {
            args.hideLoader()
            EventBus.$emit('error-return')
            alert('Dogodila se greška pri izdavanju ' + errorReceiptMsg + ' : ' + result.data.header.error)
          }
        })
    },

    async open (loader, workorder) {
      var receipt = workorder
      this.receipt = workorder
      this.workorder = workorder
      this.selectedPrinter = state.getSelectedPrinter()
      this.dialog = true
      await this.getPointsOfSale(receipt)

      this.user = await this.getUser()
      if (this.user) {
        if (this.userIsSalesman) {
          this.dialog = true
        } else {
          this.showMsgBox({ text: 'Za izdavanje računa morate imati privilegiju prodavača.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        }
      }
      EventBus.$emit('stopButtonLoader')
    },

    setReceiptData () {
      var receipt = this.receipt
      this.setAssociateData(receipt)
      this.cart = []

      if (receipt.note) {
        this.note = receipt.note
      }

      if (receipt.issue_date_time) {
        this.invoiceDate = receipt.issue_date_time * 1000
      }

      if (receipt.delivery_date) {
        this.deliveryDate = receipt.delivery_date * 1000
      }

      if (receipt.payment_due) {
        this.valueDate = receipt.payment_due * 1000
      }

      if (receipt.language) {
        this.languages.forEach(l => {
          if (l.value === receipt.language) {
            this.selectedLanguage = l
          }
        })
      }

      if (receipt && receipt.workorder_items && receipt.workorder_items.length > 0) {
        receipt.workorder_items.forEach(item => {
          var it = clone(item)

          it.unitsAndPrices = [{}]
          var id = uuidv4()

          it.prices = { EUR: { active: true, base: it.pdv.base, price: it.pdv.base + it.pdv.total, taxes: [{ name: 'PDV ' + it.pdv.rate / 100, rate: it.pdv.rate }] } }
          it.warehouse_units = { default_sale: id, units: { [id]: { base_unit: true, default_sale: true, prices: it.prices, is_selling_item: true, storage_amount: 1000, storage_unit: it.selling_unit } } }

          var unitsAndPrices = []

          unitsAndPrices.push({ id: id, storageUnit: it.selling_unit, price: it.prices })

          var cartItem = { data: it, unitsAndPrices: unitsAndPrices, quantity: it.amt / 1000 }

          if (it.discount) {
            cartItem.discountAmt = it.discount.rate / 100
            cartItem.formattedDiscount = cartItem.discountAmt.toFixed(2).replace('.', ',')
          }

          this.cart.push(cartItem)

          cartItem.price = cartItem.data.single_item_price / 100

          this.cart.forEach((_, k) => {
            this.initialCartItemSet(k, true)
          })

          this.selectedItems = this.cart
        })
      }
    },

    setAssociateData (receipt) {
      this.associateAddress = receipt?.type?.payer_address ?? ''
      this.associateZip = receipt?.type?.payer_zip_code ?? ''
      this.associateCity = receipt?.type?.payer_city ?? ''
      this.associateCountry = receipt?.type?.payer_country ?? ''
      this.associateByOib = receipt?.type?.payer_oib ?? ''
      this.associateByName = receipt?.type?.payer_name ?? ''
    },

    async getCashRegister (registerId) {
      const register = await df.doc(`cash_registers/${registerId}`).get()

      var reg = register.data()
      this.cashRegisters = [{ ...reg, text: reg.name, value: reg.id, disabled: true }]
      this.cashRegister = this.cashRegisters[0]
      this.paymentTypes = [
        { name: 'Transakcijski račun', value: 'T', type: 'R' }
      ]

      this.paymentType = this.paymentTypes[0]
      this.calculateReferenceNumber()
    },

    getEuCountries () {
      const listener = df.doc('project_constants/countries').onSnapshot(doc => {
        this.euCountries = doc.data().eu_countries
      })
      this.listeners.push(listener)
    },

    calculateReferenceNumber () {
      let model = '00'
      let receiptNum = clone(this.receiptNumber)
      receiptNum = receiptNum.replaceAll('/', '-')
      if (this.pnbType === 0) {
        let formattedReceiptNumber = receiptNum.replace(/[^0-9-]+/, '')
        formattedReceiptNumber = formattedReceiptNumber.replaceAll('--', '-')
        this.pnbModel = model
        this.pnb = formattedReceiptNumber
      } else if (this.pnbType === 1) {
        const oib = this.getOib()
        if (!oib) return
        model = '01'
        const randomKey = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
        const forSecurity = oib.toString() + randomKey.toString()
        const securityNum = this.controlDigit(forSecurity)
        this.pnbModel = model
        this.pnb = oib + '-' + randomKey + '-' + securityNum
      }
    },

    async getPointsOfSale (receipt) {
      const location = await df.doc(`locations/${receipt.location_details.id}`).get()
      this.location = location.data()

      this.onSelectLocation()
      this.locations = [this.location]

      await this.getCashRegisters()

      // await this.getCashRegister(receipt.designation.register_id)
      this.calculateReferenceNumber()
    },

    close () {
      this.resetAll()
      this.dialog = false
    },

    showPnb () {
      // (cashRegisterType === 'VP' || cashRegisterType === 'ALL')
      if (this.cashRegisterType === 'VP' || this.cashRegisterType === 'ALL') {
        if (this.paymentType.type === 'R' || this.paymentType.type === 'V' || this.paymentType.type === 'P') {
          return true
        }
      }
      return false
    },

    addHR () {
      this.pnbModel = 'HR'
    },

    getNextWorkingDay (date) {
      // Prvi radni dan se računa nakon 8 dana
      const futureDate = new Date(date)
      futureDate.setDate(futureDate.getDate() + 8)

      // Provera da li je to vikend, ako jeste, pomera na prvi radni dan
      while (futureDate.getDay() === 0 || futureDate.getDay() === 6) {
        futureDate.setDate(futureDate.getDate() + 1)
      }

      return futureDate.getTime()
    },

    formatNumber (item, number) {
      if (item.data.manual_price_input) return '- EUR'
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
        number
      )
    },

    onPnbTypeChange () {
      this.calculateReferenceNumber()
    },

    resetAll () {
      this.cart = []
      this.selectedItems = []
      this.total = 0
      this.fullTotal = 0
      this.formatedTotal = '0.00'
      this.formattedTotalMp = '0.00'
      this.formattedTotalVp = '0.00'
      this.addEmptyCartItem(0)
      this.associate = undefined
      this.associateByName = undefined
      this.associateByOib = undefined
      this.associateAddress = undefined
      this.associateZip = undefined
      this.associateCity = undefined
      this.associateCountry = undefined
      this.note = ''
      // this.invoiceDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.invoiceDate = Date.now()
      this.valueDate = this.getNextWorkingDay(this.invoiceDate)
      this.deliveryDate = Date.now()
      this.issueDateTime = (Math.floor(new Date().getTime() / 1000))
      this.pnbModel = ''
      this.pnb = ''
      this.wholeSaleRcptType = ''
      this.associatesPanel = []
      this.commentPanel = []
      this.location = undefined
      this.cashRegister = undefined
      this.paymentType = {}
      this.cashRegisters.forEach(r => {
        r.disabled = false
      })
      this.locationDisabled = false
      this.calculateReferenceNumber()
    },

    onSelectAssociate () {
      this.populateAddressFields(this.associateByName)
      if (this.associateByName?.oibs && this.associateByName.oibs.length > 0) {
        this.associateByOib = this.associateByName.oibs[0]
      } else {
        this.associateByOib = undefined
      }
      this.pnbTypes[1].disabled = false
      this.calculateReferenceNumber()
    },

    onSelectAssociateOib () {
      const hasOibs = this.associateByOib?.oibs && this.associateByOib.oibs.length > 0
      if (hasOibs && this.takenOibs.includes(this.associateByOib.oibs[0])) {
        this.populateAddressFields(this.associateByOib)
        this.associateByName = this.associateByOib?.name ?? undefined
      }
      this.pnbTypes[1].disabled = false
      this.calculateReferenceNumber()
    },

    populateAddressFields (collection) {
      this.associateAddress = collection?.address ?? undefined
      this.associateZip = collection?.zip_code ?? undefined
      this.associateCity = collection?.city ?? undefined
      this.associateCountry = collection?.country ?? undefined
    },

    canAddNewItem () {
      let canAddNewItem = false
      for (let i = 1; i < this.cart.length; i++) {
        if (!this.cart[i].data?.name || !this.cart[i].unit || !this.cart[i].tax || !this.cart[i].price) {
          canAddNewItem = true
        }
      }

      return canAddNewItem
    },

    addEmptyCartItem (mode = 1) {
      let canAddNewItem = true
      for (let i = 0; i < this.cart.length; i++) {
        if (!this.cart[i].data?.name || !this.cart[i].unit || !this.cart[i].tax || !this.cart[i].price) {
          canAddNewItem = false
        }
      }

      if (!canAddNewItem) return

      this.cart.push({ data: { name: '', type: undefined } })

      if (this.$refs.formatted_total && mode === 1) {
        this.$nextTick(() =>
          this.$refs.formatted_total.scrollIntoView({ behavior: 'smooth', block: 'start' })
        )
      }
    },

    removeCartItem (counter) {
      // Ne daj obrisati jedini item na računu
      const tempCart = clone(this.cart)
      this.cart = []
      if (tempCart.length > 1) {
        tempCart.splice(counter, 1)
        this.selectedItems.splice(counter, 1)
        tempCart.forEach(item => {
          this.cart.push({ ...item })
        })
      } else {
        this.resetAll()
      }
      this.calculateTotals()
    },

    prepareItemForManualInput (name, index) {
      const itemId = uuidv4()
      const prices = {
        EUR: {
          price: 0,
          taxes: []
        }
      }
      prices.EUR.price = 0

      const item = {
        data: {
          id: itemId,
          active: true,
          status: 'OK',
          name: name,
          manual_price_input: true,
          prices: prices,
          type: 'SERVICE',
          freeItem: true
        },
        unitsAndPrices: []
      }

      // Ako su ova polja već popunjena u formi, prekopiraj ih ovdje
      if (this.cart[index].unitsAndPrices) {
        item.unitsAndPrices = this.cart[index].unitsAndPrices
      }
      if (this.cart[index].unit) {
        item.unit = this.cart[index].unit
      }
      if (this.cart[index].tax) {
        item.tax = { ...this.cart[index].tax }
        const tax = { ...item.tax }
        tax.rate *= 10000
        prices[this.currency].taxes.push(tax)
      }
      if (this.cart[index].quantity) {
        item.quantity = this.cart[index].quantity
      }
      if (this.cart[index].price) {
        item.price = this.cart[index].price
        prices[this.currency].price = item.price * 100
      }

      if (this.cart[index].discountAmt) {
        item.discountAmt = this.cart[index].discountAmt
      }
      return item
    },

    selectItem (index) {
      if (!this.selectedItems[index]) return
      // Kod ručnog unosa vrati string, a kod odabira object
      if (typeof (this.selectedItems[index]) === 'string') {
        this.selectedItems[index] = this.prepareItemForManualInput(this.selectedItems[index], index)
      }
      this.cart[index] = clone(this.selectedItems[index])

      this.updateCartItem(index)
    },

    updateQuantity (index) {
      this.cart[index].quantity = Number(this.cart[index].formattedQuantity)
      this.cart[index].formattedQuantity = this.cart[index].quantity.toFixed(2).replace('.', ',')
      this.updateCartItem(index)
    },

    updatePrice (index) {
      // this.cart[index].manualPrice = Number(this.cart[index].formattedPrice)
      // this.cart[index].price = this.cart[index].manualPrice
      this.cart[index].price = Number(this.cart[index].formattedPrice)

      this.cart[index].unit.price[this.currency].price = this.cart[index].price * 100
      this.cart[index].unit.price[this.currency].base = this.cart[index].priceBase

      if (this.cart[index].unit?.price) {
        this.cart[index].data.prices = this.cart[index].unit?.price
      }

      // if ((this.paymentType.value === 'T' || this.paymentType.value === 'P') && this.cart[index].tax) {
      //   this.cart[index].manualPrice *= (1 + this.cart[index].tax.rate)
      // }

      this.cart[index].formattedPrice = this.cart[index].price.toFixed(2).replace('.', ',')

      this.updateCartItem(index)
    },

    updateDiscount (index) {
      this.cart[index].discountAmt = Number(this.cart[index].formattedDiscount)
      this.cart[index].formattedDiscount = this.cart[index].discountAmt.toFixed(2).replace('.', ',')
      this.updateCartItem(index)
    },

    initialCartItemSet (index) {
      // Ako artikl nema količinu, stavi 1
      if (!this.cart[index].quantity) {
        this.cart[index].quantity = 1
        this.cart[index].fomattedQuantity = '1,00'
      }

      // Ako artik nema popust, stavi 0
      if (!this.cart[index].discountAmt) {
        this.cart[index].discountAmt = 0
        this.cart[index].formattedDiscount = '0,00'
      }

      if (this.cart[index].data && this.cart[index].unitsAndPrices) {
        this.cart[index].units = this.cart[index].unitsAndPrices

        const taxes = this.cart[index].data.prices[this.currency].taxes
        if (taxes && taxes.length > 0) {
          if (!this.cart[index].tax) {
            const tax = { ...taxes[0] }
            if (tax) {
              tax.rate /= 10000
              this.cart[index].tax = tax
            }
          }
        }

        const defaultSalesUnit = this.cart[index].data.warehouse_units?.default_sale
        if (defaultSalesUnit) {
          const unit = this.cart[index].units.find(unit => unit.id === defaultSalesUnit)
          if (!this.cart[index].unit) {
            if (unit) {
              this.cart[index].unit = unit
              if (unit.price[this.currency]?.price) {
                this.cart[index].pricePdv = clone(unit.price[this.currency].price)
                this.cart[index].priceBase = clone(unit.price[this.currency].base)
              }
            } else {
              if (this.cart[index].units?.length > 0) {
                const unit = this.cart[index].units[0]
                this.cart[index].unit = unit

                if (!this.cart[index].manualPrice) {
                  this.cart[index].pricePdv = clone(unit.price[this.currency].price)
                  this.cart[index].priceBase = clone(unit.price[this.currency].base)
                }
              }
            }
          } else {
            if (!this.cart[index].manualPrice && typeof this.cart[index].unit !== 'string') {
              this.cart[index].pricePdv = clone(this.cart[index].unit?.price[this.currency].price)
              this.cart[index].priceBase = clone(this.cart[index].unit?.price[this.currency].base)
            }
          }
        }

        // Dodaj popust
        this.cart[index].data.discounted_price = 0
        this.cart[index].data.discount = null

        if (this.cart[index].discountAmt !== undefined && this.cart[index].discountAmt > 0) {
          this.cart[index].data.discounted_price = Number(this.calculateDiscount(this.cart[index]).toFixed(2))

          this.cart[index].data.discount = {
            active: true,
            amount: this.cart[index].data.single_item_price - this.cart[index].data.discounted_price * 100,
            name: 'Popust',
            rate: Number(this.cart[index].discountAmt) * 100,
            type: 'RATE'
          }
        }
      } else {
        // Firma nema skladište
        if (this.cart[index].data.prices[this.currency]?.active) {
          if (!this.cart[index].manualPrice) {
            this.cart[index].pricePdv = clone(this.cart[index].data.prices[this.currency].price)
            this.cart[index].priceBase = clone(this.cart[index].data.prices[this.currency].base)

            // this.cart[index].price = this.cart[index].data.prices[this.currency].price / 100
            // if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
            //   this.cart[index].price = this.cart[index].unit?.price[this.currency].base / 100
            // }
          }
          const taxRate = this.cart[index].data.prices[this.currency].taxes[0].rate / 10000
          if (taxRate !== undefined && taxRate !== null) {
            this.cart[index].tax = this.taxes.find(item => item.rate === taxRate)
          }
          if (this.cart[index].data.selling_unit) {
            this.cart[index].unit = ({ storageUnit: this.cart[index].data.selling_unit })
          }

          // Dodaj popust
          this.cart[index].data.discounted_price = 0
          if (this.cart[index].discountAmt !== undefined && this.cart[index].discountAmt > 0) {
            this.cart[index].data.discounted_price = Number(this.calculateDiscount(this.cart[index]).toFixed(2))

            this.cart[index].data.discount = {
              active: true,
              amount: this.cart[index].data.single_item_price - this.cart[index].data.discounted_price * 100,
              name: 'Popust',
              rate: Number(this.cart[index].discountAmt) * 100,
              type: 'RATE'
            }
          }
        }
      }

      if (this.cart[index].price && this.cart[index].price > 0 && this.cart[index].tax && (this.paymentType.value === 'T' || this.paymentType.value === 'P')) {
        this.cart[index].priceBase = Math.round(this.cart[index].price * 100)
        this.cart[index].pricePdv = this.cart[index].priceBase * (1 + this.cart[index].tax.rate)
      } else if (this.cart[index].price && this.cart[index].price > 0 && this.cart[index].tax) {
        this.cart[index].pricePdv = Math.round(this.cart[index].price * 100)
        this.cart[index].priceBase = this.cart[index].pricePdv / (1 + this.cart[index].tax.rate)
      }

      this.cart[index].formattedPrice = this.cart[index].price ? this.cart[index].price.toFixed(2) : ''
      this.cart[index].formattedQuantity = this.cart[index].quantity ? this.cart[index].quantity.toFixed(2) : ''
      this.cart[index].formattedDiscount = this.cart[index].discountAmt ? this.cart[index].discountAmt.toFixed(2) : ''

      const firstItemIsInserted = this.cart[0].price && this.cart[0].quantity && this.cart[0].data.name
      this.cashRegisters.forEach(r => {
        r.disabled = firstItemIsInserted && r.type !== this.cashRegister.type
      })
      this.locationDisabled = !!firstItemIsInserted

      this.calculateTotals()
      this.updatePrice(index)
    },

    updateCartItem (index) {
      // Ako artikl nema količinu, stavi 1
      if (!this.cart[index].quantity) {
        this.cart[index].quantity = 1
        this.cart[index].fomattedQuantity = '1,00'
      }

      // Ako artik nema popust, stavi 0
      if (!this.cart[index].discountAmt) {
        this.cart[index].discountAmt = 0
        this.cart[index].formattedDiscount = '0,00'
      }

      // Ovo je bio bug ako uzmes mjernu jedinicu koja nije defaultna,
      // onda cijena ostane kriva i validator ne da izdati racun
      if (this.cart[index].unit?.price) {
        this.cart[index].data.prices = this.cart[index].unit?.price
      }

      if (this.cart[index].data && this.cart[index].unitsAndPrices) {
        this.cart[index].units = this.cart[index].unitsAndPrices

        const taxes = this.cart[index].data.prices[this.currency].taxes
        if (taxes && taxes.length > 0) {
          if (!this.cart[index].tax) {
            const tax = { ...taxes[0] }
            if (tax) {
              tax.rate /= 10000
              this.cart[index].tax = tax
            }
          }
        }

        const defaultSalesUnit = this.cart[index].data.warehouse_units?.default_sale
        if (defaultSalesUnit) {
          const unit = this.cart[index].units.find(unit => unit.id === defaultSalesUnit)
          if (!this.cart[index].unit) {
            if (unit) {
              this.cart[index].unit = unit
              if (unit.price[this.currency]?.price) {
                if (!this.cart[index].manualPrice) {
                  this.cart[index].pricePdv = clone(unit.price[this.currency].price)
                  this.cart[index].priceBase = clone(unit.price[this.currency].base)

                  this.cart[index].price = unit.price[this.currency].price / 100
                }
              }
            } else {
              if (this.cart[index].units?.length > 0) {
                const unit = this.cart[index].units[0]
                this.cart[index].unit = unit

                if (!this.cart[index].manualPrice) {
                  this.cart[index].pricePdv = clone(unit.price[this.currency].price)
                  this.cart[index].priceBase = clone(unit.price[this.currency].base)

                  this.cart[index].price = unit.price[this.currency].price / 100
                }
              }
            }
          } else {
            if (!this.cart[index].manualPrice && typeof this.cart[index].unit !== 'string') {
              this.cart[index].pricePdv = clone(this.cart[index].unit?.price[this.currency].price)
              this.cart[index].priceBase = clone(this.cart[index].unit?.price[this.currency].base)
            }
          }
        }

        // TODO obrisati nakon 01.04
        // Korigiranje cijene za transakcijski racun
        if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
          if (this.cart[index].unit?.price && this.cart[index].unit.price[this.currency]?.price?.base) {
            if (!this.cart[index].manualPrice) {
              this.cart[index].price = this.cart[index].unit.price[this.currency].base
            }
          } else {
            if (this.cart[index]?.unit?.price) {
              const rate = this.cart[index]?.unit?.price[this.currency].taxes[0].rate / 10000 + 1
              if (!this.cart[index].manualPrice) {
                this.cart[index].price = Number((this.cart[index].unit.price[this.currency].price / rate / 100).toFixed(2))
              }
            }
          }
        }

        // Dodaj popust
        this.cart[index].data.discounted_price = 0

        this.cart[index].data.discount = null

        if (this.cart[index].discountAmt !== undefined && this.cart[index].discountAmt > 0) {
          this.cart[index].data.discounted_price = Number(this.calculateDiscount(this.cart[index]).toFixed(2))

          if (!this.cart[index].manualPrice) {
            this.cart[index].data.single_item_price = this.cart[index].data.prices[this.currency].price
          } else {
            this.cart[index].data.single_item_price = this.cart[index].manualPrice * 100
          }

          this.cart[index].data.discount = {
            active: true,
            amount: this.cart[index].data.single_item_price - this.cart[index].data.discounted_price * 100,
            name: 'Popust',
            rate: Number(this.cart[index].discountAmt) * 100,
            type: 'RATE'
          }
        }
      } else {
        // Firma nema skladište
        if (this.cart[index].data.prices[this.currency]?.active) {
          if (!this.cart[index].manualPrice) {
            this.cart[index].pricePdv = clone(this.cart[index].data.prices[this.currency].price)
            this.cart[index].priceBase = clone(this.cart[index].data.prices[this.currency].base)

            this.cart[index].price = this.cart[index].data.prices[this.currency].price / 100
          }
          const taxRate = this.cart[index].data.prices[this.currency].taxes[0].rate / 10000
          if (taxRate !== undefined && taxRate !== null) {
            this.cart[index].tax = this.taxes.find(item => item.rate === taxRate)
          }
          if (this.cart[index].data.selling_unit) {
            this.cart[index].unit = ({ storageUnit: this.cart[index].data.selling_unit })
          }

          // Korigiranje cijene za - transakcijski racun
          if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
            if (this.cart[index].data.prices[this.currency]?.base) {
              if (!this.cart[index].manualPrice) {
                this.cart[index].price = this.cart[index].data.prices[this.currency].base / 100
                this.cart[index].data.single_item_price = this.cart[index].data.prices[this.currency].base
              }
            } else {
              const rate = this.cart[index].tax.rate + 1
              if (!this.cart[index].manualPrice) {
                this.cart[index].price = Number((this.cart[index].data.prices[this.currency].price / rate / 100).toFixed(2))
                this.cart[index].data.single_item_price = this.cart[index].price
              }
            }
          }

          // Dodaj popust
          this.cart[index].data.discounted_price = 0
          if (this.cart[index].discountAmt !== undefined && this.cart[index].discountAmt > 0) {
            this.cart[index].data.discounted_price = Number(this.calculateDiscount(this.cart[index]).toFixed(2))

            if (!this.cart[index].manualPrice) {
              this.cart[index].data.single_item_price = this.cart[index].data.prices[this.currency].price
            } else {
              this.cart[index].data.single_item_price = this.cart[index].manualPrice * 100
            }

            this.cart[index].data.discount = {
              active: true,
              amount: this.cart[index].data.single_item_price - this.cart[index].data.discounted_price * 100,
              name: 'Popust',
              rate: Number(this.cart[index].discountAmt) * 100,
              type: 'RATE'
            }
          }
        }
      }

      if (this.cart[index].price && this.cart[index].price > 0 && this.cart[index].tax && (this.paymentType.value === 'T' || this.paymentType.value === 'P')) {
        this.cart[index].priceBase = Math.round(this.cart[index].price * 100)
        this.cart[index].pricePdv = this.cart[index].priceBase * (1 + this.cart[index].tax.rate)
      } else if (this.cart[index].price && this.cart[index].price > 0 && this.cart[index].tax) {
        this.cart[index].pricePdv = Math.round(this.cart[index].price * 100)
        this.cart[index].priceBase = this.cart[index].pricePdv / (1 + this.cart[index].tax.rate)
      }

      this.cart[index].formattedPrice = this.cart[index].price ? this.cart[index].price.toFixed(2) : ''
      this.cart[index].formattedQuantity = this.cart[index].quantity ? this.cart[index].quantity.toFixed(2) : ''
      this.cart[index].formattedDiscount = this.cart[index].discountAmt ? this.cart[index].discountAmt.toFixed(2) : ''

      const firstItemIsInserted = this.cart[0].price && this.cart[0].quantity && this.cart[0].data.name
      this.cashRegisters.forEach(r => {
        r.disabled = firstItemIsInserted && r.type !== this.cashRegister.type
      })
      this.locationDisabled = !!firstItemIsInserted

      this.calculateTotals()
    },

    calculateTotals () {
      let accumulator = 0
      this.cart.forEach(item => {
        if (item.data.discounted_price) {
          accumulator += item.data.discounted_price * item.quantity * 100
        } else {
          if (item.price && item.quantity) {
            accumulator += item.price * item.quantity * 100
          }
        }
      })
      const fullTotal = this.cart.reduce((accumulator, item) => accumulator + (item.price * item.quantity * 100), 0)
      const total = accumulator

      this.fullTotal = Number(fullTotal.toFixed(2))
      this.total = Number(total.toFixed(2))
      if (isNaN(this.total)) { this.total = 0 }
      this.formatedTotal = (Math.round(this.total) / 100).toFixed(2).replace('.', ',')

      // NOVI IZRACUN SAMO ZA TRANSAKCIJSKI RACUN SA VP CIJENAMA
      var totalMp = 0
      var totalVp = 0
      var fullTotalMp = 0
      var fullTotalVp = 0
      var pdvs = []

      this.cart.forEach((item, i) => {
        if (this.isDefined(item.quantity) && this.isDefined(item.priceBase) && this.isDefined(item.pricePdv)) {
          if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
            totalVp += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.priceBase * item.quantity
            totalMp += item.data.discounted_price ? item.data.discounted_price * 100 * (1 + item.tax.rate) * item.quantity : item.pricePdv * item.quantity

            fullTotalVp += item.priceBase * item.quantity
            fullTotalMp += item.pricePdv * item.quantity
          } else {
            totalMp += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.pricePdv * item.quantity
            totalVp += item.data.discounted_price ? (item.data.discounted_price * 100) / (1 + item.tax.rate) * item.quantity : item.priceBase * item.quantity

            fullTotalVp += item.priceBase * item.quantity
            fullTotalMp += item.pricePdv * item.quantity
          }

          var pdvName = 'p' + parseInt(item.tax.rate * 10000)
          if (!pdvs[pdvName]) {
            pdvs[pdvName] = {
              base: 0,
              pdv: 0,
              total: 0
            }
          }

          if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
            pdvs[pdvName].base += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.priceBase * item.quantity
            pdvs[pdvName].total += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * (1 + item.tax.rate) * item.quantity : item.pricePdv * item.quantity
            pdvs[pdvName].rate = item.tax.rate * 10000
            pdvs[pdvName].pdv += pdvs[pdvName].total - pdvs[pdvName].base
          } else {
            pdvs[pdvName].total += this.isDefined(item.data.discounted_price) ? item.data.discounted_price * 100 * item.quantity : item.pricePdv * item.quantity
            pdvs[pdvName].base += this.isDefined(item.data.discounted_price) ? (item.data.discounted_price * 100) / (1 + item.tax.rate) * item.quantity : item.priceBase * item.quantity
            pdvs[pdvName].rate = item.tax.rate * 10000
            pdvs[pdvName].pdv = pdvs[pdvName].total - pdvs[pdvName].base
          }

          if (item.discount?.amount > 0) {
            item.discount.amount = fullTotalMp - totalMp
          }
        }
      })
      if (isNaN(totalVp)) { totalVp = 0 }
      if (isNaN(totalMp)) { totalMp = 0 }
      if (isNaN(fullTotalVp)) { fullTotalVp = 0 }
      if (isNaN(fullTotalMp)) { fullTotalMp = 0 }

      this.formattedTotalVp = (totalVp / 100).toFixed(2).replace('.', ',')
      this.formattedTotalMp = (totalMp / 100).toFixed(2).replace('.', ',')
    },

    isDefined (param) {
      if (param === undefined) {
        return false
      }

      if (param === null) {
        return false
      }

      if (param === 0) {
        return false
      }

      return true
    },

    calculateDiscount (item) {
      if (!item.manualPrice) {
        return item.price - (item.price * item.discountAmt) / 100
      }
      return item.manualPrice - (item.manualPrice * item.discountAmt) / 100
    },

    getCategory (item) {
      var categories = ''
      if (item.data.categories && item.data.categories.length > 0) {
        item.data.categories.forEach(cat => {
          categories += cat.name.toUpperCase() + ' | '
        })
      }
      return categories.replace(/\|([^|]*)$/, '$1')
    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    getItems () {
      this.isLoading = true
      this.items = []
      if (!this.location.id) { return }
      df.collection(`location_pricelist/${this.location.id}/items`)
        .where('status', '==', 'OK')
        .where('active', '==', true)
        .where(`prices.${this.currency}.active`, '==', true)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((it) => {
              const item = {}
              item.data = it.data()
              // TODO remove after script adds base to all
              if (item.data.prices.EUR?.base === null || item.data.prices.EUR?.base === undefined) {
                item.data.prices.EUR.base = Math.round(item.data.prices.EUR.price / (1 + (item.data.prices.EUR.taxes[0].rate / 10000)))
              }

              if (item.data.warehouse_units?.units) {
                item.unitsAndPrices = []
                Object.keys(item.data.warehouse_units.units).forEach(key => {
                  const units = item.data.warehouse_units.units[key]
                  if (units?.storage_unit && units?.prices && units?.prices[this.currency]?.active) {
                    if (item.data.manual_price_input) {
                      units.prices[this.currency].price = '0'
                    }

                    // TODO remove after script adds base to all
                    if (units.prices && units.prices.EUR && (units.prices.EUR?.base === null || units.prices.EUR?.base === undefined)) {
                      units.prices.EUR.base = Math.round(units.prices.EUR.price / (1 + (units.prices.EUR.taxes[0].rate / 10000)))
                    }

                    item.unitsAndPrices.push({ id: key, storageUnit: units.storage_unit, price: units.prices })
                  }
                })
              }
              if (item.data.active) {
                this.items.push(item)
              }
            })
          }
        }).catch(() => {
          console.error('An error has occured')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    setPaymentTypes () {
      if (this.cashRegisterType === 'VP') {
        this.paymentTypes = [
          { name: 'Transakcijski račun', value: 'T', type: 'R' },
          { name: 'Ponuda', value: 'P', type: 'P' },
          { name: 'Virman', value: 'V', type: 'V' }
        ]
        this.paymentType = { name: 'Transakcijski račun', value: 'T', type: 'R' }
      } else if (this.cashRegisterType === 'ALL') {
        this.paymentTypes = [
          { name: 'Transakcijski račun', value: 'T', type: 'R' },
          { name: 'Ponuda', value: 'P', type: 'P' },
          { name: 'Virman', value: 'V', type: 'V' }
        ]

        this.paymentType = { name: 'Transakcijski račun', value: 'T', type: 'R' }

        if (state.getCurrentCompany().certificate !== undefined && state.getCurrentCompany().certificate !== null && state.getCurrentCompany().certificate !== '') {
          this.paymentTypes = [
            { name: 'Transakcijski račun', value: 'T', type: 'R' },
            { name: 'Ponuda', value: 'P', type: 'P' },
            { name: 'Gotovina', value: 'G', type: 'BO' },
            { name: 'Kartice', value: 'K', type: 'BO' },
            { name: 'Ostalo', value: 'O', type: 'BO' },
            { name: 'Virman', value: 'V', type: 'V' }
          ]
          this.paymentType = { name: 'Gotovinski račun', value: 'G', type: 'BO' }
        }
      } else {
        this.paymentTypes = [
          { name: 'Gotovina', value: 'G', type: 'BO' },
          { name: 'Kartice', value: 'K', type: 'BO' },
          { name: 'Ostalo', value: 'O', type: 'BO' }
        ]
        this.paymentType = { name: 'Gotovina', value: 'G', type: 'BO' }
      }
    },

    async getUser () {
      let user
      const doc = await df.doc(`users/${auth.currentUser.uid}`).get()
      if (doc && doc.data()) {
        user = doc.data()
        this.roles = Object.keys(user.roles)
        if (this.roles.includes('PRODAVAČ')) {
          this.userIsSalesman = true
        }
      }
      return user
    },

    rValidation () {
      if (!this.associateByName && !this.associateByOib) {
        this.confirm({
          title: 'Kupac nije odabran',
          message: 'Želite li izdati račun / ponudu s nepoznatim kupcem?',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'OK',
            cancelText: 'Odustani'
          }
        }).then(response => {
          if (response) {
            this.confirmClose()
            this.associateByName = { name: 'Kupac građanin', id: 'kupac-gradanin' }
            this.createReceipt()
          } else {
            // eslint-disable-next-line no-useless-return
            return
          }
        })
      } else {
        this.createReceipt()
      }
    },

    validateForm () {
      if (!this.cart[0]?.data?.name) {
        this.showMsgBox({ title: 'test', text: 'Potreban minimalno jedan artikl u košarici', actions: ['ok'], okBtnText: 'OK', color: 'error' })
        return
      }
      if (this.paymentType.type !== 'BO') {
        this.rValidation()
      } else {
        this.createReceipt()
      }
    },
    stopLoader () {
      this.loading = false
      this.resetAll()
      this.calculateReferenceNumber()
      this.close()
    }
  },

  async mounted () {
    this.getMultiplier()
    this.addEmptyCartItem()

    this.getEuCountries()

    EventBus.$off('set-selected-printer')
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })

    EventBus.$off('error-return')
    EventBus.$on('error-return', () => {
      this.loading = false
    })

    EventBus.$off('showError')
    EventBus.$on('showError', (data) => {
      this.$refs.showError.open(data.message)
    })

    this.selectedVies = 'inside'
    this.selectedCustomerType = 'legal'
  },

  beforeDestroy () {
    this.detachListeners()
  }
}
</script>

<style scoped>
.totals-row {
  max-height: 3rem;
}

.total-price {
  display: block;
  font-size: 1.8rem;
  color: black;
  margin-top: 0.3rem;
  margin-right: 1rem;
}

.curreny-selector {
  max-width: 6rem;
}

.attention>>>.v-text-field__slot input {
  color: red;
}

.normal>>>.v-text-field__slot input {
  color: black;
}

.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 30px;
  border-radius: 25px !important;
}

::v-deep .small-radio i {
  font-size: 15px;
}

::v-deep .delete-icon i {
  font-size: 6px;
}

::v-deep .small-radio label {
  font-size: 15px;
  padding-left: 0px;
  margin-left: -4px;
}

::v-deep .small-radio .v-radio {
  padding: 0px;
}

::v-deep .small-radio [class*="__ripple"] {
  left: 0;
}

.my-overlay>>>.v-overlay__content {
  width: 100%;
  height: 100%;
}

.datetimepicker {
  width: 100%;
  height: 40px !important;
}

.datepickerLabel {
  font-size: 12px !important;
  font-weight: bold;
}
</style>
