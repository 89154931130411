<template>
  <div>
    <v-dialog :max-width="width"
      v-model="dialog"
      v-if="dialog"
      content-class="my-custom-dialog"
      @keydown.esc="close">
      <v-card tile
        color="white"> </v-card>
      <v-card-text class="white">
        <v-container>
          <v-row class="panel-row"
            v-if="workorder && workorder.workorderOptions">
            <v-tabs class="pb-5"
              v-if="showTabs"
              grow
              v-model="activeTab"
              @change="changeActiveTab">
              <v-tab v-for="tab of tabs"
                :key="tab.id">
                <v-icon class="pr-2">{{ tab.icon }}</v-icon>
                <span><b>{{ tab.name }}</b></span>
              </v-tab>
            </v-tabs>
            <v-col v-for="(item, index) in activeOptions"
              :key="index"
              :cols="12"
              class="mb-1 pb-0 mt-0 pt-0">
              <v-btn style="border-radius: 12px"
                block
                tile
                depressed
                min-height="5rem"
                color="#295586"
                class="mb-0 pb-0"
                return-object
                @click="menuClicked(item, workorder)"
                @keypress.enter.prevent="menuClicked(item, workorder)">
                <span class="white--text tile btn-lg text-xs-center">{{
                  item.title
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      width: '500px',
      workorder: undefined,
      tabs: [],
      activeTab: 0,
      activeOptions: [],
      tabOptions1: [],
      tabOptions2: [],
      tabOptions3: []

    }
  },
  watch: {

  },
  computed: {
    showTabs () {
      var showTabs = false
      if (this.tabs && this.tabs.length > 0) {
        showTabs = true
      }

      return showTabs
    }
  },

  methods: {
    open (workorder, defaultActiveTab = 0) {
      this.workorder = workorder

      if (this.workorder.workorderOptions) {
        this.workorder.workorderOptions.sort((a, b) => a.title.localeCompare(b.title))
      }

      this.tabs = [
        { id: 0, name: 'Promijeni', icon: 'mdi-note-edit-outline' },
        { id: 1, name: 'Preuzmi', icon: 'mdi-tray-arrow-down' },
        { id: 2, name: 'Dodatno', icon: 'mdi-menu' }
      ]

      this.activeOptions = []
      this.tabOptions1 = []
      this.tabOptions2 = []
      this.tabOptions3 = []

      this.reformatOptions(defaultActiveTab)

      this.dialog = true
    },
    changeActiveTab () {
      switch (this.activeTab) {
        case 0:
          this.activeOptions = this.tabOptions1
          break
        case 1:
          this.activeOptions = this.tabOptions2
          break
        case 2:
          this.activeOptions = this.tabOptions3
          break
      }
    },
    reformatOptions (defaultActiveTab) {
      this.workorder.workorderOptions.forEach(option => {
        switch (option.title) {
          case 'Uredi radni nalog':
            this.tabOptions1.push(option)
            break

          case 'Preuzmi radni nalog':
            this.tabOptions2.push(option)
            break

          case 'Pošalji radni nalog E-mailom':
            this.tabOptions2.push(option)
            break

          case 'Kreiraj račun':
            this.tabOptions1.push(option)
            break

          case 'Kreiraj ponudu':
            this.tabOptions1.push(option)
            break

          case 'Promijeni status':
            this.tabOptions1.push(option)
            break
        }
      })

      this.activeTab = defaultActiveTab
      switch (defaultActiveTab) {
        case 0:
          this.activeOptions = this.tabOptions1
          break
        case 1:
          this.activeOptions = this.tabOptions2
          break
        case 2:
          this.activeOptions = this.tabOptions3
          break
      }
    },

    setAllForOffersOrVirmans () {
      this.tabs = []
      this.activeTab = 0
      this.tabOptions1 = this.workorder.workorderOptions
      this.activeOptions = this.tabOptions1
    },
    close () {
      this.dialog = false
    },
    menuClicked (item) {
      this.$emit('loader')
      this.$emit('menuClicked', item, this.workorder)
      this.close()
    }
  }
}
</script>

<style scoped>
@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }

  span.tile {
    display: block;
    white-space: normal;
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }

  span.tile {
    display: block;
    white-space: normal;
  }
}

.btn-lg {
  font-size: 1.5rem;
  font-weight: 700;
}

.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 30px;
  border-radius: 25px;
}
</style>
